import {
  GET_LEGAL_CLIENT_TYPES,
  GET_PROJECT_ACTIVITIES,
  GET_UNSUBMITTED_ACTIVITIES,
  GET_UNSUBMITTED_ACTIVITIES_EXPORT_EXCEL,
  GET_PROJECT_ACTIVITY_DETAIL,
  GET_LEGAL_CLIENT_LIST,
  GET_LEGAL_CLIENT_BY_ID,
  GET_PROJECTS_FOR_TIME_FUNCTION,
  GET_PROJECTS_FOR_TIME_FUNCTION_FORMATED,
  GET_RUNNING_TASK,
  GET_CLIENT_PROJECT_DDL_LIST,
  GET_BILLING_DESIGNATIONS,
  GET_ACTIVE_CLIENTS_LIST,
  GET_TODAYS_CLOCK_WORK,
  GET_MY_TEAM_ACTIVITIES,
  GET_CALENDAR_ACTIVITIES,
  GET_NATURE_OF_WORK_DDL,
  CONTRACT_SAVED,
  GET_ACTIVITY_AND_BILLING_ROLES,
  GET_HOURS_WORKED_BY_CLIENT,
  GET_HOURS_WORKED_BY_PEOPLE,
  GET_RAW_REPORT,
  GET_HOURS_COMMITTED,
  GET_ACTIVITY_DASHBOARD,
  GET_PEOPLE_IN_PROJECT_VIEW_BY_PROJECT,
  GET_PEOPLE_IN_PROJECT_VIEW_BY_PEOPLE,
  GET_MEMBER_BY_NAME,
  GET_COUNTER_REPORT,
  GET_PROJECT_MATTER_ID,
  SAVE_CLIENT_PROJECT,
  GET_PROJECT_DETAILS,
  GET_CONTRACT_DETAILS,
  SET_LEGAL_CLIENT_LOADING,
  DELETE_PROJECT_CONTRACT,
  GET_SECRETARIAL_CHARGES_TYPES,
  GET_DEPARTMENTS_TO_HANDLE_PROJECT_CASE,
  GET_PROJECT_DROPDOWN,
} from "actions/types";

const initialState = {
  legalClientTypes: null,
  legalClientList: null,
  legalClientById: null,
  projectActivities: null,
  unSubmittedActivities: null,
  unSubmittedActivitiesExportExcel: null,
  projectActivityDetail: null,
  projectsForTimeFunction: [],
  formatedProjectsForTimeFunction: [],
  runningTask: null,
  clientProjectListForDDl: [],
  natureOfWorkDDl: [],
  clockData: [],
  myTeamMembers: null,
  myTeamActivities: null,
  calendarActivities: null,
  billingDesignations: null,
  billingDesignationsDdl: [],
  activityAndBillingRoles: null,
  hoursWorkedByClient: null,
  hoursWorkedByPeople: null,
  rawReport: null,
  rawReportToExport: null,
  hoursCommitted: null,
  activityDashboard: null,
  peopleInProject_ViewByProject: null,
  peopleInProject_ViewByPeople: null,
  membersByName: null,
  membersByNameDll: [],
  counterReport: null,
  projectMatterId: null,
  departments: [],
  projectsDDL:[],
  projectDetails: null,
  contractDetails: null,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_LEGAL_CLIENT_TYPES:
      return {
        ...state,
        legalClientTypes: action.payload,
      };
    case GET_PROJECT_DROPDOWN:
      return {
        ...state,
        projectsDDL:action.payload,
      }

    case GET_LEGAL_CLIENT_LIST:
      return {
        ...state,
        legalClientList: action.payload,
      };

    case GET_LEGAL_CLIENT_BY_ID:
      return {
        ...state,
        legalClientById: action.payload,
      };

    case GET_PROJECT_ACTIVITIES:
      return {
        ...state,
        projectActivities: action.payload,
      };

    case GET_UNSUBMITTED_ACTIVITIES:
      return {
        ...state,
        unSubmittedActivities: action.payload,
      };

    case GET_UNSUBMITTED_ACTIVITIES_EXPORT_EXCEL:
      return {
        ...state,
        unSubmittedActivitiesExportExcel: action.payload,
      };

    case GET_PROJECT_ACTIVITY_DETAIL:
      return {
        ...state,
        projectActivityDetail: action.payload,
      };

    case GET_PROJECTS_FOR_TIME_FUNCTION:
      return {
        ...state,
        projectsForTimeFunction: action.payload,
      };

    case GET_PROJECTS_FOR_TIME_FUNCTION_FORMATED:
      return {
        ...state,
        formatedProjectsForTimeFunction: action.payload,
      };

    case GET_RUNNING_TASK:
      return {
        ...state,
        runningTask: action.payload,
      };

    case GET_CLIENT_PROJECT_DDL_LIST:
      return {
        ...state,
        clientProjectListForDDl: action.payload,
      };
    case GET_TODAYS_CLOCK_WORK:
      return {
        ...state,
        clockData: action.payload,
      };

    case GET_MY_TEAM_ACTIVITIES:
      return {
        ...state,
        myTeamMembers: action.payload ? action.payload.MyTeamMembers : [],
        myTeamActivities: action.payload
          ? action.payload.MyTeamsActivities
          : null,
      };

    case GET_BILLING_DESIGNATIONS:
      return {
        ...state,
        billingDesignations: action.payload,
        billingDesignationsDdl: action.formatedBillingDesignations,
      };

    case GET_ACTIVE_CLIENTS_LIST:
      return {
        ...state,
        activeClientsList: action.payload,
      };

    case GET_CALENDAR_ACTIVITIES:
      return {
        ...state,
        calendarActivities: action.payload,
      };

    case GET_SECRETARIAL_CHARGES_TYPES:
      return {
        ...state,
        secretarialChargesTypes: action.payload,
      };

    case GET_DEPARTMENTS_TO_HANDLE_PROJECT_CASE:
      return {
        ...state,
        departments: action.payload,
      };

    case GET_NATURE_OF_WORK_DDL:
      return {
        ...state,
        natureOfWorkDDl: action.payload,
      };

    case CONTRACT_SAVED:
      return {
        ...state,
        contractSaved: action.payload,
      };

    case GET_ACTIVITY_AND_BILLING_ROLES:
      return {
        ...state,
        activityAndBillingRoles: action.payload,
      };
    case GET_HOURS_WORKED_BY_CLIENT:
      return {
        ...state,
        hoursWorkedByClient: action.payload,
      };
    case GET_HOURS_WORKED_BY_PEOPLE:
      return {
        ...state,
        hoursWorkedByPeople: action.payload,
      };
    case GET_RAW_REPORT: {
      if (action.isExport) {
        return {
          ...state,
          rawReportToExport: action.payload,
        };
      } else {
        return {
          ...state,
          rawReport: action.payload,
        };
      }
    }
    case GET_HOURS_COMMITTED:
      return {
        ...state,
        hoursCommitted: action.payload,
      };
    case GET_ACTIVITY_DASHBOARD:
      return {
        ...state,
        activityDashboard: action.payload,
      };
    case GET_PEOPLE_IN_PROJECT_VIEW_BY_PROJECT:
      return {
        ...state,
        peopleInProject_ViewByProject: action.payload,
      };
    case GET_PEOPLE_IN_PROJECT_VIEW_BY_PEOPLE:
      return {
        ...state,
        peopleInProject_ViewByPeople: action.payload,
      };
    case GET_MEMBER_BY_NAME:
      return {
        ...state,
        membersByName: action.membersByName,
        membersByNameDll: action.membersByNameDll,
      };

    case GET_COUNTER_REPORT:
      return {
        ...state,
        counterReport: action.payload,
      };

    case SET_LEGAL_CLIENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_PROJECT_MATTER_ID:
      return {
        ...state,
        projectMatterId: action.payload,
      };

    case SAVE_CLIENT_PROJECT:
      return {
        ...state,
        savedClientProject: action.payload,
      };

    case GET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.payload,
      };
    case GET_CONTRACT_DETAILS:
      return {
        ...state,
        contractDetails: action.payload,
      };

    case DELETE_PROJECT_CONTRACT:
      return {
        ...state,
        contractDeleted: action.payload,
      };

    default:
      return state;
  }
}
