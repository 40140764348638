import React from "react";

//CHAKRA
import { Tabs, Tab, TabList, TabPanels, TabPanel } from "@chakra-ui/react";

//INTERFACE
export interface IRigoTabsData {
  label: string;
  content: JSX.Element;
  badge?: JSX.Element;
  isDisabled?: boolean;
}

interface ITabsProps {
  data: IRigoTabsData[];
  isLazy: boolean;
  align: "center" | "end" | "start";
  size: "sm" | "md" | "lg";
  onChange: (index: number) => void;
  tabContainerProps?: any;
  tabProps?: any;
  tabListProps?: any;
  tabPanelContainerProps?: any;
  tabPanelProps?: any;
  index?: number;
}

export const RigoTabs = (props: ITabsProps) => {
  const {
    data,
    isLazy,
    align,
    size,
    onChange,
    tabContainerProps = {},
    tabProps = {},
    tabListProps = {},
    tabPanelContainerProps = {},
    tabPanelProps = {},
    index
  } = props;

  return (
    <Tabs {...{ align, isLazy, size, onChange, index }}>
      <TabList bgColor='#fff' borderRadius='5px'>
        {data.map((tab, index) => (
          <Tab fontSize='14px' key={index} isDisabled={!!tab.isDisabled}>
            {tab.label} {tab.badge && tab.badge}
          </Tab>
        ))}
      </TabList>
      <TabPanels {...tabPanelContainerProps}>
        {data.map((tab, index) => (
          <TabPanel key={index} p='0'>
            {tab.content}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

RigoTabs.defaultProps = {
  isLazy: false,
  align: "left",
  size: "md",
};

export default RigoTabs;
