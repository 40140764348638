import { Heading, HeadingProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  Colors,
  FontFamily,
  FontSizes,
  FontWeights,
  Spacing
} from '@rigotech/hrms-data';

interface PageHeadingProps extends HeadingProps {
  heading?: string;
  children?: string | number | ReactNode;
  color?: Colors;
  // fontSize?: FontSizes.Headings;
}
export const PageHeading = (props: PageHeadingProps) => {
  const {
    heading,
    children,
    color = Colors.HeadingText,
    fontSize = FontSizes.Headings.SectionHeadingLarge,
    ...rest
  } = props;

  return (
    <Heading
      fontWeight={FontWeights.ExtraBold}
      // fontWeight={FontWeights.BoldMedium}
      fontSize={fontSize}
      lineHeight='28px'
      color={color}
      mb={Spacing.ml}
      {...rest}
    >
      {heading ?? children ?? ''}
    </Heading>
  );
};

export default PageHeading;
