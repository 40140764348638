import { useCallback } from 'react';
import { Box, Flex, FormLabel, IconButton, Text } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import { FiUploadCloud } from 'react-icons/fi';
import { TbReplace } from 'react-icons/tb';
import { MdClose } from 'react-icons/md';

type DropzoneProps = {
  label?: string;
  multiple?: boolean;
  selectedFile: any[];
  setSelectedFile: any;
  customMessage?: string;
  accept?: string[];
};

export const DropzoneUpload = ({
  label,
  multiple,
  selectedFile,
  setSelectedFile,
  customMessage = '',
  accept = ['image/*', 'application/pdf'],
}: DropzoneProps) => {
  const onDrop = useCallback((acceptedFiles: any) => {
    // Handle the uploaded files here
    setSelectedFile(acceptedFiles);
  }, []);

  const handleRemoveFile = (idx: any) => {
    const file = [...selectedFile];
    file?.splice?.(idx, 1);
    setSelectedFile(file);
  };

  return (
    <>
      {label && (
        <Text
          color='#000000b3'
          fontWeight='400'
          fontSize='14px'
          lineHeight='1.5'
          marginBottom={1}
        >
          {label}
        </Text>
      )}
      <Dropzone accept={accept} onDrop={onDrop} multiple={multiple}>
        {({ getRootProps, getInputProps }) => (
          <Box {...getRootProps()} width='100%' position='relative'>
            <input {...getInputProps()} />
            <Box
              align-items='center'
              display='flex'
              borderWidth={1}
              borderRadius='lg'
              justifyContent='center'
              paddingInlineStart={6}
              paddingInlineEnd={6}
              pb={4}
              pt={4}
              borderColor='#D7D7D7'
            >
              <Flex
                className='chakra-stack'
                alignItems='center'
                flexDirection='column'
              >
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  flexGrow={0}
                  flexShrink={0}
                  width={10}
                  height={10}
                  bg='gray.50'
                  borderRadius='lg'
                >
                  {selectedFile?.length > 0 ? (
                    <TbReplace color='#4C5466' />
                  ) : (
                    <FiUploadCloud color='#4C5466' />
                  )}
                </Box>
                <Flex mt='12px' alignItems='center' gap='12px'>
                  <Text fontSize='13px' fontWeight={600} color='#3D6BAB'>
                    Click to {selectedFile?.length > 0 ? 'Replace' : 'upload'}
                  </Text>
                  <Text fontSize='13px' as='p' color='#4C5466' opacity={0.8}>
                    or drag and drop
                  </Text>
                </Flex>
                <Text
                  mt='4px'
                  as='p'
                  fontSize='12px'
                  color='#4C5466'
                  opacity={0.8}
                >
                  {!customMessage && 'Image, Word, Excel or PDF Document'}
                  {customMessage}
                </Text>
              </Flex>
            </Box>
          </Box>
        )}
      </Dropzone>
      {selectedFile &&
        selectedFile?.map?.((file: any, idx: any) => (
          <Flex
            alignItems='center'
            borderRadius='lg'
            borderWidth={1}
            justifyContent='space-between'
            key={idx}
            mb={1}
            mt={1}
          >
            <Box color='#3D6BAB'>{file?.name}</Box>
            {/* <Box maxH={'20px'} maxW={'15px'}>
              <img src={URL.createObjectURL(file)} />
            </Box> */}

            <IconButton
              aria-label='close-icon-button'
              icon={<MdClose />}
              onClick={() => handleRemoveFile(idx)}
              size='sm'
            />
          </Flex>
        ))}
    </>
  );
};

export default DropzoneUpload;
