import { Heading, HeadingProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  Colors,
  FontFamily,
  FontSizes,
  FontWeights,
} from '@rigotech/hrms-data';

interface SectionHeadingProps extends HeadingProps {
  heading?: string;
  children?: string | number | ReactNode;
  // color?: Colors;
  // fontSize?: FontSizes.Headings;
}

export const SectionHeading = (props: SectionHeadingProps) => {
  const {
    heading,
    children,
    color = Colors.TextPrimaryDark,
    fontSize = FontSizes.Headings.SectionHeading,
    ...rest
  } = props;

  return (
    <Heading
      fontWeight={FontWeights.BoldMedium}
      fontSize={fontSize}
      lineHeight='22px'
      color={color}
      {...rest}
    >
      {heading ?? children ?? ''}
    </Heading>
  );
};

export default SectionHeading;
