import { Text, TextProps } from '@chakra-ui/react';
import { ReactNode } from 'react';
import {
  Colors,
  FontFamily,
  FontSizes,
  FontWeights,
} from '@rigotech/hrms-data';

interface RigoTextProps extends TextProps {
  text?: string;
  children?: string | number | ReactNode;
  // color?: Colors;
  // fontSize?: FontSizes.Texts;
}

export const RigoText = (props: RigoTextProps) => {
  const {
    text,
    children,
    fontSize = FontSizes.Texts.Normal,
    color = Colors.TextDark,
    ...rest
  } = props;

  return (
    <Text
      fontWeight={FontWeights.Normal}
      fontSize={fontSize}
      lineHeight='16px'
      color={color}
      {...rest}
    >
      {text ?? children ?? ''}
    </Text>
  );
};

export default RigoText;
